import React from 'react';

const InputFile = () => {
  const inputRef = React.useRef();

  const handleFileUpload = () => {
    inputRef.current.click();
  };

  return (
    <div>
      <label htmlFor="file" className="file" style={{fontSize:"15px"}}>
      Choose File <ion-icon name="cloud-upload-outline" />
      </label>
      <input
        id="file"
        ref={inputRef}
        type="file"
        style={{
          display: 'none',
        }}
      />
      <span id="file-name">No file chosen</span>
      <style>
        {`
          input[type=file] {
            display: none;
          }
          .file {
            display: inline-block;
            position: relative;
            color: #364868;
            border: 1px solid #364868;
            background: hsl(0 0 0/0);
            padding: 4px 5px;
            border-radius: 4px;
            cursor: pointer;
          }
          .file input[type=file] {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            
          }
          #file-name {
            margin-left: 5px;
            font-size:16px;
          }
        `}
      </style>
    </div>
  );
};

export default InputFile;
