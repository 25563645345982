import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrashCan, faEye, faUser, faFileEdit } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import ListAltIcon from '@mui/icons-material/ListAlt';
library.add(faPen, faTrashCan, faEye, faUser, faFileEdit);

const styles = {
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    border: "none",
  },
  th: {
    padding: '16px',
    textAlign: 'center',
    color: "#364868",
    fontSize: "14px",
    fontWeight: "700",
    borderBottom: '1px solid #ddd',
  },
  thFirst: {
    width: '50%',
    paddingLeft: '16px',
    borderBottom: '1px solid #F0F2F5',
    textAlign: 'left',
    fontSize: "14px",
    fontWeight: "700",
    color: "#364868",
  },
  tdCenter: {
    textAlign: "center",
    justifyContent: "center",
  },
  td: {
    padding: '16px',
    textAlign: 'center',
    fontSize: "12px",
    color: "#364868",
    borderBottom: '1px solid #F0F2F5',
    width: "12.5%"
  },
  tdFirst: {
    width: '50%',
    textAlign: 'left',
  },
  actionBtn: {
    border: 'none',
    background: "transparent",
    cursor: 'pointer',
  },
  actionBtnIcon: {
    fontSize: '16px',
  },

};

function CustomTable() {
  const [data, setData] = useState([]);
  const url = `${process.env.REACT_APP_API_URL}/templates`;
  const token = "2|LSwsqbKrlfoyaPA29KorbhAlJbfpsZJWK6kVQ74B"

  useEffect(() => {
    fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response problem ' + response.statusText);
      }
      return response.json();
    })
    .then(data => {
      setData(data.data);
    })
    .catch(error => {
      console.log('Problem with the fetch operation:', error);
    });
  }, []);

  const handleDeleteRow = async (id) => {
    const deleteUrl = `${process.env.REACT_APP_API_URL}/templates/${id}`;
    
    try {
      const response = await fetch(deleteUrl, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error('Network response problem ' + response.statusText);
      }
  
      const updatedData = data.filter(row => row.id !== id);
      console.log('Updated data:', updatedData); 
      setData(updatedData);
    } catch (error) {
      console.log('Problem with the delete operation:', error);
    }
  };
  return (
    <table style={styles.table}>
      <thead>
        <tr>
          <th style={styles.thFirst}>Template Name</th>
          <th style={styles.th}>Preview</th>
          <th style={styles.th}>Use</th>
          <th style={styles.th}>Edit</th>
          <th style={styles.th}>Delete</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map((row) => (
          <tr key={row.id}>
            <td style={{ ...styles.td, ...styles.tdFirst }}>
              <ListAltIcon style={{ color: "#364868", marginRight: "8px" }} />
              {row.name}
            </td>
            <td style={styles.td}>
              <button style={{ ...styles.actionBtn, ...styles.tdCenter }}>
                <FontAwesomeIcon icon={faEye} style={{ color: "#364868" }} />
              </button>
            </td>
            <td style={styles.td}>
              <a href='<Campaigns />'>
                <button style={styles.actionBtn}>
                  <FontAwesomeIcon icon={faFileEdit} style={{ color: "#364868" }} />
                </button>
              </a>
            </td>
            <td style={styles.td}>
              <a href='<Campaigns />'>
                <button style={styles.actionBtn}>
                  <FontAwesomeIcon icon={faPen} style={{ color: "#364868" }} />
                </button>
              </a>
            </td>
            <td style={styles.td}>
              <button style={styles.actionBtn} onClick={() => handleDeleteRow(row.id)}>
                <FontAwesomeIcon icon={faTrashCan} style={{ color: "#364868", marginRight: "10px" }} />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CustomTable;