import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TwinButton from "components/TwinButton/TwinButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import TrackButtons from "layouts/dashboards/campaigns/TrackButtons";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

import Infocircleicon from "assets/images/icons/InfocircleIcon";
import MoonIcon from "assets/images/icons/MoonIcon.jsx";
import TestIcon from "assets/images/icons/testIcon.jsx";

const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
    border: "none",
    backgroundColor: "white",
    overflow: "auto",
    padding: "20px",
    marginTop: "30px",
    borderRadius: "20px",
    overflowX: "auto",
  },
  th: {
    padding: "16px",
    textAlign: "center",
    color: "#364868",
    fontSize: "14px",
    fontWeight: "700",
    borderBottom: "1px solid #ddd",
  },
  thFirst: {
    paddingLeft: "16px",
    borderBottom: "1px solid #F0F2F5",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "700",
    color: "#364868",
  },
  td: {
    padding: "16px",
    textAlign: "center",
    fontSize: "12px",
    color: "#364868",
    borderBottom: "1px solid #F0F2F5",
  },
  tdFirst: {
    textAlign: "left",
    fontSize: "15px",
  },
  actionBtn: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
  },
  actionBtnIcon: {
    fontSize: "16px",
  },
  status: {
    backgroundColor: "#364868",
    color: "white",
    height: "40%",
    display: "inline-block",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: "5px",
    padding: "0px 5px 0px 5px",
  },
  attempts: {
    display: "inline-block",
    backgroundColor: "#4CAF50",
    color: "white",
    width: "30px",
    lineHeight: "30px",
    textAlign: "center",
    borderRadius: "30%",
  },
};

const initialData = [
  {
    id: 1,
    email: "onetwothree@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "4",
  },
  {
    id: 2,
    email: "testtest1@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "5",
  },
  {
    id: 3,
    email: "testtest1@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "5",
  },
  {
    id: 4,
    email: "testtest1@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "5",
  },
];

const HeadersData = [
  { key: "Email", label: "Email" },
  { key: "Status", label: "Status" },
  { key: "LastUpdate", label: "Last Update" },
  { key: "BlockAttempts", label: "Block Attempts" },
  { key: "Delete", label: "Delete" },
];

function HouseKeeping() {
  const [activeStep, setActiveStep] = useState(0);

  const [data, setData] = useState(initialData);
  const [selectedTable, setSelectedTable] = useState(true);

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  const steps = selectedTable ? ["Suppereson List"] : ["Blocked Domains"];

  const consolelogger = () => {
    setSelectedTable((prev) => !prev);
  };

  const [tabOrientation, setTabOrientation] = useState({
    clicks: "horizontal",
    opens: "horizontal",
    links: "horizontal",
    template: "horizontal",
  });

  const [tabValue, setTabValue] = useState({
    clicks: 0,
    opens: 0,
    links: 0,
    template: 0,
  });
  useEffect(() => {
    function handleTabsOrientation() {
      const newOrientation = window.innerWidth < breakpoints.values.sm ? "vertical" : "horizontal";
      setTabOrientation({
        clicks: newOrientation,
        opens: newOrientation,
        links: newOrientation,
        template: newOrientation,
      });
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (key) => (event, newValue) => {
    setTabValue((prev) => ({ ...prev, [key]: newValue }));
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox display="flex" pt={2}>
        <MDTypography variant="h4">ActRight</MDTypography>
        <Tooltip title="Edit" placement="bottom">
          <MDTypography variant="body1" sx={{ cursor: "pointer", mx: 1 }}>
            <Icon color="inherit">edit</Icon>
          </MDTypography>
        </Tooltip>
      </MDBox>
      <MDTypography>Housekeeping</MDTypography>
      <MDBox
        style={{
          backgroundColor: "white",
          height: "78px",
          display: "flex",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <Tabs
          onClick={consolelogger}
          style={{ width: "40%", textAlign: "center" }}
          orientation={tabOrientation.template}
          value={tabValue.template}
          onChange={handleSetTabValue("template")}
        >
          <Tab icon={<Infocircleicon />} label="Unconfirmed subscribers" sx={{ width: "4px" }} />
          <Tab icon={<MoonIcon />} label="Inactive subscribers" sx={{ width: "4px" }} />
        </Tabs>
      </MDBox>

      {activeStep === 0 && selectedTable ? (
        <MDBox style={{ backgroundColor: "#FFFDEE", marginTop: "25px", display:"flex"}}>
          
          <MDTypography style={{ fontSize: "15px", padding: "20px" }}>  <TestIcon style={{marginRight:"5px"}} />
            Housekeeping for Unconfirmed subscribers allows you to bulk remove subscribers who
            signed up to double opt-in lists but did not click the confirmation link to confirm
            their subscription.
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox style={{ backgroundColor: "#FFFDEE", marginTop: "25px", display:"flex" }}>
          <MDTypography style={{ fontSize: "15px", padding: "20px" }}>  <TestIcon style={{marginRight:"5px"}}  />
            Housekeeping for Inactive subscribers allows you to bulk remove subscribers who did not
            open or click any campaigns that you have ever sent to them.
          </MDTypography>
        </MDBox>
      )}

      <div>
        {activeStep === 0 && selectedTable ? (
          <table style={{ ...styles.table }}>
            <thead>
              <tr>
                <th style={{ ...styles.thFirst, verticalAlign: "middle" }}>
                  <MDTypography style={{ height: "60px", paddingTop: "20px" }}>
                    Unconfirmed subscribers
                  </MDTypography>
                </th>
              </tr>
            </thead>

            <tbody>
              <p style={{ height: "80px", padding: "20px" }}>
                No housekeeping needed as no double opt-in lists are found
              </p>
            </tbody>
          </table>
        ) : (
          <table style={{ ...styles.table }}>
            <thead>
              <tr>
                {HeadersData.map((item) => (
                  <th key={item.key} style={styles.th}>
                    {item.label}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td style={styles.tdFirst}>{item.email}</td>
                  <td style={styles.td}>
                    <div style={styles.status}>{item.status}</div>
                  </td>
                  <td style={styles.td}>{item.lastUpdate}</td>
                  <td style={styles.td}>
                    <div style={styles.attempts}>{item.BlockAttempts}</div>
                  </td>
                  <td onClick={() => handleDelete(item.id)} style={styles.td}>
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      style={{ color: "#364868", cursor: "pointer" }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </DashboardLayout>
  );
}

export default HouseKeeping;
