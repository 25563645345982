import React, { useState } from 'react';
import styled from 'styled-components';
import LockIcon from '@mui/icons-material/Lock';
import PropTypes from "prop-types";
import DescriptionIcon from '@mui/icons-material/Description';

const RadioInputsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #EEE;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 300px;
  font-size: 14px;

`;

const RadioLabel = styled.label`
  flex: 1 1 auto;
  text-align: center;
`;

const RadioInput = styled.input`
  display: none;
`;

const RadioName = styled.span`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.25rem 0.5rem; 
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;

  ${RadioInput}:checked + & {
    background-color: #fff;
    font-weight: 600;
    
  }
`;

const TwinButton = ({color,onSelect}) => {
  const [selected, setSelected] = useState('HTML');

  const handleChange = (event) => {
    setSelected(event.target.value);
    onSelect()
  };
  return (
    <RadioInputsWrapper>
      <RadioLabel>
        <RadioInput
          type="radio"
          name="radio"
          value="HTML"
          checked={selected === 'HTML'}
          onChange={handleChange}
        />    <RadioName>
         <DescriptionIcon style={{ marginRight: "5px" }} />
    
            Suppression List</RadioName>
      </RadioLabel>
      <RadioLabel>
        <RadioInput
          type="radio"
          name="radio"
          value="React"
          checked={selected === 'React'}
          onChange={handleChange}
        />
        <RadioName>
        <LockIcon style={{ marginRight: "5px" }} /> 
        Blocked Domains
        </RadioName>
      </RadioLabel>

    </RadioInputsWrapper>
  );
};

TwinButton.defaultProps = {
  color: "info",
 
};

// Typechecking props of the MDAlert
TwinButton.propTypes = {
  color: PropTypes.oneOf([
    "green",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  onSelect: PropTypes.func.isRequired,
  // dismissible: PropTypes.bool,
  // children: PropTypes.node.isRequired,
};

export default TwinButton