import React, { useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Formik, Form, Field } from "formik";
import NewCampaign from "layouts/dashboards/campaigns/NewCampaign";
import EditorComponent from "layouts/dashboards/campaigns/EditorComponent";
import MDInput from "components/MDInput";
import InputLabel from "@mui/material/InputLabel";
import TrackButtons from "layouts/dashboards/campaigns/TrackButtons";
import InputFile from "assets/theme/components/inputTypeFile/InputFile";
import { styled } from "@mui/system";
import Stepper from "@mui/material/Stepper";
import MDButton from "components/MDButton";
import NewsLetterPreview from "layouts/dashboards/campaigns/previewIndex";

const formData = {
  formField: {
    subject: { type: "text", name: "subject", placeholder: "Subject of this Email" },
    fromName: { type: "text", name: "fromName", placeholder: "From Name" },
    fromEmail: { type: "email", name: "fromEmail", placeholder: "From Email" },
    replytoEmail: { type: "email", name: "replytoEmail", placeholder: "Reply to Email" },
  },
  values: {
    subject: "",
    fromName: "",
    fromEmail: "",
    replytoEmail: "",
    plainText: "",
    webVersionLanguage: "",
    queryString: "",
    trackClicks: 0,
    checkLinks: 0,
    trackOpens: 0,
    saveTemplate: 0,
  },
};

const validationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject is required"),
  fromName: Yup.string().required("From Name is required"),
  fromEmail: Yup.string().email("Invalid email").required("From Email is required"),
  replytoEmail: Yup.string().email("Invalid email").required("Reply to Email is required"),
  plainText: Yup.string().required("Plain text version is required"),
  webVersionLanguage: Yup.string().required("Web Version Language is required"),
  queryString: Yup.string().required("Query string is required"),
  trackClicks: Yup.number().required("Track Clicks is required"),
  checkLinks: Yup.number().required("Check for broken links is required"),
  trackOpens: Yup.number().required("Track Opens is required"),
  saveTemplate: Yup.number().required("Save as template is required"),
});

const FormsField = styled(TextField)`
  background-color: #ffffff;
  width: 100%;
`;

const FormsLabel = styled(InputLabel)`
  color: #364868;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  overflow: visible;
`;

function getSteps() {
  return ["New Campaign", "Newsletter preview"];
}

function Campaigns() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  let token = "2|LSwsqbKrlfoyaPA29KorbhAlJbfpsZJWK6kVQ74B"
  const url = `https://alternsend.srulad1.com/api/v1/campaigns`;


  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleSubmit = async (values) => {
    const trackingMap = {
      0: "yes",
      1: "no",
      2: "anonymously"
    };

    const data = {
      name: values.subject,
      subject: values.subject,
      content: values.plainText,
      email_service_id: 1,
      template_id: values.template_id,
      from_name: values.fromName,
      from_email: values.fromEmail,
      reply_to_email: values.replytoEmail,
      is_open_tracking: trackingMap[values.trackOpens],
      is_click_tracking: trackingMap[values.trackClicks],
      send_to_all: 1,
      save_as_draft: values.saveTemplate ? 1 : 0,
      scheduled_at: "2020-07-24 08:46:54",
      tags: [],
      plain_text_version: values.plainText,
    };

    console.log(values);

    try {
      const response = await axios.post(url, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      toast.success("Campaign created successfully!");
      console.log(response.data);
    } catch (error) {
      toast.error("Failed to create campaign. Please try again.");
      console.error(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <MDBox p={2}>
        <MDBox display="flex" mb={2}>
          <MDTypography variant="h4">ActRight</MDTypography>
          <Tooltip title="Edit" placement="bottom">
            <MDTypography variant="body1" sx={{ cursor: "pointer", mx: 1 }}>
              <Icon color="inherit">edit</Icon>
            </MDTypography>
          </Tooltip>
        </MDBox>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={formData.values}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3} gap={10}>
                      {activeStep === 0 && (
                        <MDBox>
                          <NewCampaign
                            formData={{
                              ...formData,
                              values,
                              handleChange,
                              handleBlur,
                              errors,
                              touched,
                            }}
                          />
                          <EditorComponent />
                          <MDBox py={3}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Field
                                  as={MDInput}
                                  name="plainText"
                                  placeholder="Plain text version of this email"
                                  multiline
                                  rows={10}
                                  sx={{ width: "100%" }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.plainText}
                                />
                                {errors.plainText && touched.plainText && (
                                  <div>{errors.plainText}</div>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <FormsLabel>Web Version Language</FormsLabel>
                                    <Field
                                      as={FormsField}
                                      name="webVersionLanguage"
                                      placeholder="Don't translate"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.webVersionLanguage}
                                    />
                                    {errors.webVersionLanguage && touched.webVersionLanguage && (
                                      <div>{errors.webVersionLanguage}</div>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <FormsLabel>Query string</FormsLabel>
                                    <Field
                                      as={FormsField}
                                      name="queryString"
                                      placeholder="eg. utm_source=newsletter&utm_medium"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.queryString}
                                    />
                                    {errors.queryString && touched.queryString && (
                                      <div>{errors.queryString}</div>
                                    )}
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDButton
                                      variant="contained"
                                      size="medium"
                                      color="light"
                                      sx={{
                                        width: "200px",
                                        padding: "10px 2px",
                                        fontWeight: "400",
                                        textTransform: "unset",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <MDTypography variant="body1" fontSize="14px">
                                        Essential tags (HTML only)
                                      </MDTypography>
                                    </MDButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </MDBox>
                          <MDBox>
                            <TrackButtons values={values} setFieldValue={setFieldValue} />
                            <MDBox marginTop={4} spacing={4}>
                              <MDTypography variant="body1" fontSize="16px" mb={1}>
                                Attachments
                              </MDTypography>
                              <InputFile />
                            </MDBox>
                          </MDBox>
                          <MDBox display="flex" justifyContent="flex-end" gap={2} marginTop={4}>
                            <MDButton variant="gradient" color="info" size="medium" type="submit">
                              Save
                            </MDButton>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="medium"
                              onClick={handleNext}
                            >
                              Save & Next
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      )}
                      {activeStep === 1 && <NewsLetterPreview />}
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Campaigns;
