import React from 'react';
import { Card, List, ListItem, ListItemText, Divider, Typography } from '@mui/material';
import PropTypes from "prop-types";
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

const StatsCard = ({stats}) => {
  return (
    <Card sx={{mt: 4, width: '100%', padding: 1}}>
      <List>
       
        {stats.map(({ label, value, secondary }, index) => (
          <React.Fragment key={index}>
            <ListItem>
              <ListItemText primary={label} />
              <MDTypography variant="body2" fontSizeXXS>{value} </MDTypography>
            </ListItem>

          </React.Fragment>
        ))}
      </List>
    </Card>
  );
};

const EmailStats = () => {
  const deliveryStats = [
    { label: 'Successful deliveries', value: '14,378', secondary: '98,9%' },
    { label: 'Total opens', value: '625' },
    { label: 'Last opened', value: '3/7/24 7:55AM' },
    { label: 'Forwarded', value: '0' }
  ];

  const clickStats = [
    { label: 'Clicks per unique opens', value: '0.9%' },
    { label: 'Total clicks', value: '17' },
    { label: 'Last clicked', value: '2/7/24 7:20PM' },
    { label: 'Abuse reports', value: '1' }
  ];

  return (
    <MDBox style={{ display: 'flex', justifyContent: 'space-between', gap: '20px'}}>
      <StatsCard title="Delivery Stats" stats={deliveryStats} flex={1} />
      <StatsCard title="Click Stats" stats={clickStats} flex={1} />
    </MDBox>
  );
};

StatsCard.propTypes = {
    stats: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        secondary: PropTypes.string,
      })
    ).isRequired,
  };

export default EmailStats;
