import MDEditor from "components/MDEditor";

function EditorComponent() {
    const editorStyle = {
        height: '500px'  // You can adjust the height as necessary
      };
return (
<MDEditor value="<h1>Quill</h1>"/>
)
}

export default EditorComponent;