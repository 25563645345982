import React from "react";
import anglesDown from "assets/images/angles-down.svg";

function BlockedDomainsButton() {
  return (
    <button
      style={{
        cursor: "pointer",
        color: "white",
        fontSize: "bold",
        backgroundColor: "#364868",
        border: "none",
        borderRadius: "5px",
        padding: "5px",
        fontWeight: "bold",
        display:"flex",
        marginTop:"10px"
      }}
    >
      <img src={anglesDown} alt="Angles Down" /> 
      import and delete matching records from all lists
    </button>
  );
}

export default BlockedDomainsButton;
