import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import IconButtonComponent from "./ButtonSubscribe";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import GetAppIcon from "@mui/icons-material/GetApp";
import SubscribersChart from "./SubscribersChart";
import SubscribersTable from "./SubscribersTable";
import TableButton from "layouts/applications/data-tables/pagination";
import { Card } from "@material-ui/core";
import SubscribersBadge from "./SubscribersBadge";
import IconLabelList from "./IconsMap";
import MDBadge from "components/MDBadge";

const SubscriberLists = () => {
  const listItems = [
    { icon: "ListIcon", label: "Custom Fields", size: "medium" },
    { icon: "QueryBuilderIcon", label: "Autoresponders", size: "medium" },
    { icon: "FilterListIcon", label: "Segments", size: "medium" },
    { icon: "SubscriptionsIcon", label: "Subscribe form", size: "medium" },
    { icon: "SettingsIcon", label: "List settings", size: "small" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={2}>
        <MDBox display="flex" mb={3}>
          <MDTypography variant="h4">ActRight</MDTypography>
          <Tooltip title="Edit" placement="bottom">
            <MDTypography variant="body1" sx={{ cursor: "pointer", mx: 1 }}>
              <Icon color="inherit">edit</Icon>
            </MDTypography>
          </Tooltip>
        </MDBox>
        <MDTypography variant="Heading1">Subscribers List</MDTypography>
        <MDBox display="flex" gap={2} mt={4}>
          <IconButtonComponent icon={AddCircleIcon} type="submit">
            Add Subscribers
          </IconButtonComponent>
          <IconButtonComponent icon={RemoveCircleIcon} type="submit">
            Delete Subscribers
          </IconButtonComponent>
          <IconButtonComponent icon={NotInterestedIcon} type="submit">
            Mass Unsubscribe
          </IconButtonComponent>
          <IconButtonComponent icon={GetAppIcon} type="submit">
            Export all Subscribers
          </IconButtonComponent>
        </MDBox>
        <MDBox mt={4}>
          <Card>
            <MDBox display="flex" justifyContent="space-between" py={1} px={2}>
              <MDBox display="flex">
              <MDTypography variant="body1">List:</MDTypography>
              <MDBadge badgeContent="Innonde Master List" size="md" color="light" container />
              <MDTypography variant="body1">| Back to Lists</MDTypography>
              </MDBox>
              <IconLabelList items={listItems} />
            </MDBox>
          </Card>
        </MDBox>
        <SubscribersChart />
        <MDBox mt={4}>
          <Card>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              py={2}
              gap={4}
              px={2}
            >
              <SubscribersBadge badgeContent="758,072" color="dark" text="All" />
              <SubscribersBadge badgeContent="753,629" color="success" text="Active" />
              <SubscribersBadge badgeContent="0" color="light" text="Unconfirmed" />
              <SubscribersBadge badgeContent="4,374" color="error" text="Unsubscribed" />
              <SubscribersBadge badgeContent="0" color="dark" text="Bounced" />
              <SubscribersBadge badgeContent="0" color="dark" text="Mark as spam" />
            </MDBox>
          </Card>
        </MDBox>
        <MDBox my={4}>
          <Card>
            <SubscribersTable />
          </Card>
        </MDBox>
        <TableButton text={1} />
        <TableButton icon={faChevronRight} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SubscriberLists;
