import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Card, CardContent, Stack, Link } from "@mui/material";

function InfoRecipient() {
  const stats = ["Opened", "Clicked", "Bounced", "Unsubscribed"];
  const data = [541, 5, 159, 7];
  return (
    // mshobeli
    <MDBox sx={{}}>
      <MDBox display="flex" justifyContent="space-between" mb={2}>
        <MDBox display="flex" flexDirection="column">
          <MDTypography variant="subtitle1"  gutterBottom fontSizeXS color="dataNumberColor">
            14,537 Recipients
          </MDTypography>
          <MDTypography variant="h4" fontSizeXXS fontWeight={"regular"} pb={1}>
            Audience: ActRight (Tags: non-dups)
          </MDTypography>
          <MDTypography fontSizeXS gutterBottom>
            Subject: First Then and Now
          </MDTypography>
        </MDBox>
        <MDBox
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <MDTypography variant="subtitle1" sx={{ mr: 2, fontSize: "1rem" }}>
            Deliveries: Wed, Feb 7, 2024 3:26 PM
          </MDTypography>
          <MDBox>
            <Link href="#" underline="hover" sx={{ fontSize: "1rem" }}>
              View email
            </Link>
            <Link href="#" underline="hover" sx={{ ml: 2, fontSize: "1rem" }}>
              Download
            </Link>
            <Link href="#" underline="hover" sx={{ ml: 2, fontSize: "1rem" }}>
              Print
            </Link>
            <Link href="#" underline="hover" sx={{ ml: 2, fontSize: "1rem" }}>
              Share
            </Link>
          </MDBox>
        </MDBox>
      </MDBox>
      <Card p={4}>
        <Stack direction="row">
          {stats.map((stat, index) => (
            <Card
              key={index}
              sx={{
                flexGrow: 1,
                minWidth: "140px",
                borderRadius: 0,
                borderTopLeftRadius: index === 0 ? "12px" : 0,
                borderBottomLeftRadius: index === 0 ? "12px" : 0,
                borderTopRightRadius: index === stats.length - 1 ? "12px" : 0,
                borderBottomRightRadius: index === stats.length - 1 ? "12px" : 0,
              }}
            >
              <CardContent>
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <MDTypography variant="h5" color="dataNumberColor">
                    {data[index]}
                  </MDTypography>
                  <MDTypography color="textSecondary" variant="p">
                    {stat}
                  </MDTypography>
                </MDBox>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Card>
    </MDBox>
  );
}

export default InfoRecipient;
