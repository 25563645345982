import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import PieChart from "examples/Charts/PieChart";
import SubscribersLineChartData from "./data/subscribersLineChartData";
import MDBadgeDot from "components/MDBadgeDot";
import Card from "@mui/material/Card";
import SubscriberChartData from "./data/subscribersChartData";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";

function SubscribersChart() {
    return(
        <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} lg={3.5}>
            <Card>
              <Grid container alignItems="center" direction="column">
                <Grid item xs={7} mt={2}>
                  <PieChart chart={SubscriberChartData} height="16.5rem" />
                </Grid>
                <Grid item xs={5}>
                  <MDBox display="flex" pr={1} my={4}>
                    <MDBox>
                      <MDBadgeDot color="success" size="sm" badgeContent="96% Active" />
                    </MDBox>
                    <MDBox>
                      <MDBadgeDot color="primary" size="sm" badgeContent="4% Unsubscribe" />
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} lg={8.5}>
              <DefaultLineChart
                title="Subscribers Activity Chart"
                chart={SubscribersLineChartData}
                height="20.5rem" 
              />
            </Grid>
      </Grid>
    </MDBox>
    )
}
export default SubscribersChart;