import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import MDButton from "components/MDButton";
import { Field } from "formik";

function NewCampaign({ formData }) {
  const { formField, values, errors, touched, handleChange, handleBlur } = formData;
  const { subject, fromName, fromEmail, replytoEmail } = formField;
  const { subject: subjectV, fromName: fromNameV, fromEmail: fromEmailV, replytoEmail: replytoEmailV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Create Campaign</MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Field
              as={FormField}
              type={subject.type}
              name={subject.name}
              value={subjectV}
              placeholder={subject.placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.subject && touched.subject}
              success={subjectV.length > 0 && !errors.subject}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={FormField}
              type={fromName.type}
              name={fromName.name}
              value={fromNameV}
              placeholder={fromName.placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.fromName && touched.fromName}
              success={fromNameV.length > 0 && !errors.fromName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={FormField}
              type={fromEmail.type}
              name={fromEmail.name}
              value={fromEmailV}
              placeholder={fromEmail.placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.fromEmail && touched.fromEmail}
              success={fromEmailV.length > 0 && !errors.fromEmail}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              as={FormField}
              type={replytoEmail.type}
              name={replytoEmail.name}
              value={replytoEmailV}
              placeholder={replytoEmail.placeholder}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.replytoEmail && touched.replytoEmail}
              success={replytoEmailV.length > 0 && !errors.replytoEmail}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDButton
        variant="contained"
        size="large"
        color="light"
        sx={{
          width: "228px",
          fontSize: "14px",
          padding: "10px 2px",
          fontWeight: "400",
          textTransform: "unset",
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        <MDTypography variant="body1" fontSize="13px">
          Save and switch to HTML editor
        </MDTypography>
      </MDButton>
    </MDBox>
  );
}

NewCampaign.propTypes = {
  formData: PropTypes.shape({
    formField: PropTypes.object.isRequired,
    values: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    touched: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,
};

export default NewCampaign;
