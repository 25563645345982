import PropTypes from 'prop-types';
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import { useState, useEffect } from "react";

function TrackButtons({ values, setFieldValue }) {
  const [tabOrientation, setTabOrientation] = useState({
    clicks: "horizontal",
    opens: "horizontal",
    links: "horizontal",
    template: "horizontal",
  });

  useEffect(() => {
    function handleTabsOrientation() {
      const newOrientation = window.innerWidth < breakpoints.values.sm ? "vertical" : "horizontal";
      setTabOrientation({
        clicks: newOrientation,
        opens: newOrientation,
        links: newOrientation,
        template: newOrientation,
      });
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (key) => (event, newValue) => {
    setFieldValue(key, newValue);
  };

  return (
    <MDBox>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="body1" fontSize="16px">
                Track Clicks:
              </MDTypography>
              <Tabs
                orientation={tabOrientation.clicks}
                value={values.trackClicks}
                onChange={handleSetTabValue("trackClicks")}
                style={{ width: "50%" }}
              >
                <Tab label="Yes" style={{ width: "1px", fontSize: "14px" }} />
                <Tab label="No" style={{ width: "1px", fontSize: "14px" }} />
                <Tab label="Anonymously" style={{ width: "34px", fontSize: "14px" }} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" fontSize="16px">
                Check for broken links:
              </MDTypography>
              <Tabs
                orientation={tabOrientation.links}
                value={values.checkLinks}
                onChange={handleSetTabValue("checkLinks")}
                style={{ width: "30%" }}
              >
                <Tab label="Yes" style={{ width: "1px", fontSize: "14px" }} />
                <Tab label="No" style={{ width: "1px", fontSize: "14px" }} />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="body1" fontSize="16px">
                Track Opens:
              </MDTypography>
              <Tabs
                orientation={tabOrientation.opens}
                value={values.trackOpens}
                onChange={handleSetTabValue("trackOpens")}
                style={{ width: "50%" }}
              >
                <Tab label="Yes" style={{ width: "1px", fontSize: "14px" }} />
                <Tab label="No" style={{ width: "1px", fontSize: "14px" }} />
                <Tab label="Anonymously" style={{ width: "34px", fontSize: "14px" }} />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <MDTypography variant="body1" fontSize="16px">
                Save this email as a template:
              </MDTypography>
              <Tabs
                orientation={tabOrientation.template}
                value={values.saveTemplate}
                onChange={handleSetTabValue("saveTemplate")}
                style={{ width: "30%" }}
              >
                <Tab label="Yes" style={{ width: "1px", fontSize: "14px" }} />
                <Tab label="No" style={{ width: "1px", fontSize: "14px" }} />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
}

TrackButtons.propTypes = {
  values: PropTypes.shape({
    trackClicks: PropTypes.number,
    checkLinks: PropTypes.number,
    trackOpens: PropTypes.number,
    saveTemplate: PropTypes.number,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TrackButtons;
