import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import MDBadge from "components/MDBadge";
import NotInterestedIcon from "@mui/icons-material/NotInterested";

library.add(faPen, faTrashCan);

const styles = {
    table: {
      width: "100%",
      borderCollapse: "collapse",
      border: "none",
    },
    th: {
      padding: "16px",
      textAlign: "start",
      color: "#364868",
      fontSize: "14px",
      fontWeight: "700",
      borderBottom: "1px solid #ddd",
      width: "16.67%", 
    },
    thFirst: {
      borderBottom: "1px solid #F0F2F5",
      textAlign: "left",
      fontSize: "14px",
      fontWeight: "700",
      color: "#364868",
      width: "16.67%", 
    },
    td: {
      padding: "16px",
      textAlign: "left",
      fontSize: "12px",
      color: "#364868",
      borderBottom: "1px solid #F0F2F5",
      width: "16.67%", 
    },
    tdFirst: {
      textAlign: "left",
      width: "16.67%", 
    },
    actionBtn: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
    },
    actionBtnIcon: {
      fontSize: "16px",
    },
  };

const SubscribersData = [
  { id: 1, name: "Lisa", email: "Lisa_sched@gmail.com", listactivity: "35 mins ago" },
  { id: 2, name: "Lisa", email: "Lisa_sched@gmail.com", listactivity: "35 mins ago" },
  { id: 3, name: "Lisa", email: "Lisa_sched@gmail.com", listactivity: "35 mins ago" },
];

function SubscribersTable() {
  const [data, setData] = useState(SubscribersData);

  const handleDeleteRow = (id) => {
    setData(data.filter((row) => row.id !== id));
  };

  return (
    <table style={styles.table}>
    <thead>
      <tr>
        <th style={styles.th}>Name</th>
        <th style={styles.th}>Email</th>
        <th style={styles.th}>List Activity</th>
        <th style={styles.th}>Status</th>
        <th style={styles.th}>Unsubscribe</th>
        <th style={styles.th}>Delete</th>
      </tr>
    </thead>
    <tbody>
      {data.map((row) => (
        <tr key={row.id}>
          <td style={styles.td}>{row.name}</td>
          <td style={styles.td}>{row.email}</td>
          <td style={styles.td}>{row.listactivity}</td>
          <td style={styles.td}>
            <button style={styles.actionBtn}>
            <MDBadge badgeContent="Subscribe" size="xs" color="success"/>
            </button>
          </td>
          <td style={styles.td}>
            <button style={styles.actionBtn}>
              <NotInterestedIcon style={{ marginLeft: "28px" }}/>
            </button>
          </td>
          <td style={styles.td}>
            <button style={styles.actionBtn} onClick={() => handleDeleteRow(row.id)}>
              <FontAwesomeIcon icon={faTrashCan} style={{ color: "#364868", textAlign: "right" }} />
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  );
}

export default SubscribersTable;
