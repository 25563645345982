
const SubscribersLineChartData = {
    labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Subscribers Activity",
        color: "dark",
        data: [10, 30, 40, 120, 150, 220, 280, 250, 280],
      },
    ],
  };
  
  export default SubscribersLineChartData;