import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

function SubscribersBadge({ badgeContent, color, text }) {
    return (
      <MDBox display="flex" alignItems="center">
        <MDTypography variant="button">{text}</MDTypography>
        <MDBadge badgeContent={badgeContent} size="lg" color={color} />
      </MDBox>
    );
  }

  SubscribersBadge.propTypes = {
    badgeContent: PropTypes.string.isRequired,
    color: PropTypes.oneOf(['default', 'primary', 'secondary', 'error', 'info', 'success', 'warning']).isRequired,
    text: PropTypes.string.isRequired
  };
  
  
  export default SubscribersBadge;