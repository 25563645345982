import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types'; 

function IconButtonComponent({ type, icon: IconComponent, children, ...props }) {
  return (
    <MDButton
      variant="contained"
      size="large"
      sx={{
        display: "flex",
        fontSize: "14px",
        padding: "10px",
        fontWeight: "400",
        textTransform: "unset",
        boxShadow: "4px 4px 4px 0px #0000004D",
        color: "#364868",
        gap: "8px",
        ...props.sx 
      }}
      type={type}
      {...props} 
    >
      <Icon>
        <IconComponent />
      </Icon>
      {children}
    </MDButton>
  );
}

IconButtonComponent.propTypes = {
    type: PropTypes.string,
    icon: PropTypes.elementType.isRequired,  
    children: PropTypes.node.isRequired, 
    sx: PropTypes.object 
  };

export default IconButtonComponent;
