const Infocircleicon = () => {
  return (
    <svg className="" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 17.0625C4.554 17.0625 0.9375 13.446 0.9375 9C0.9375 4.554 4.554 0.9375 9 0.9375C13.446 0.9375 17.0625 4.554 17.0625 9C17.0625 13.446 13.446 17.0625 9 17.0625ZM9 2.0625C5.17425 2.0625 2.0625 5.17425 2.0625 9C2.0625 12.8258 5.17425 15.9375 9 15.9375C12.8258 15.9375 15.9375 12.8258 15.9375 9C15.9375 5.17425 12.8258 2.0625 9 2.0625ZM9.5625 12.375V8.94672C9.5625 8.63622 9.3105 8.38422 9 8.38422C8.6895 8.38422 8.4375 8.63622 8.4375 8.94672V12.375C8.4375 12.6855 8.6895 12.9375 9 12.9375C9.3105 12.9375 9.5625 12.6855 9.5625 12.375ZM9.76501 6.375C9.76501 5.961 9.42976 5.625 9.01501 5.625H9.00751C8.59351 5.625 8.26117 5.961 8.26117 6.375C8.26117 6.789 8.60101 7.125 9.01501 7.125C9.42901 7.125 9.76501 6.789 9.76501 6.375Z"
        fill="#25314C"
      />
    </svg>
  );
};

export default Infocircleicon;
