import React from "react";
import PropTypes from "prop-types";
import MDBox from "@mui/material/Box";
import MDTypography from "@mui/material/Typography";
import ListIcon from '@mui/icons-material/List';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FilterListIcon from '@mui/icons-material/FilterList';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import SettingsIcon from '@mui/icons-material/Settings';

const iconMap = {
  ListIcon,
  QueryBuilderIcon,
  FilterListIcon,
  SubscriptionsIcon,
  SettingsIcon
};

function IconLabelList({ items }) {
  return (
    <MDBox display="flex" gap={2}>
      {items.map((item, index) => (
        <MDBox key={index} display="flex" alignItems="center" gap={1}>
         {React.createElement(iconMap[item.icon], { fontSize: item.size || 'default' })}
          <MDTypography variant="body2" fontWeight="regular">{item.label}</MDTypography>
        </MDBox>
      ))}
    </MDBox>
  );
}

IconLabelList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOf(Object.keys(iconMap)).isRequired,
      label: PropTypes.string.isRequired,
      size: PropTypes.oneOf(['inherit', 'small', 'medium', 'large', 'default'])
    })
  ).isRequired,
};

export default IconLabelList;
