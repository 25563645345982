import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CustomTable from "../BlackList/BlackListData";

import TwinButton from "components/TwinButton/TwinButton"

function BlackList() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <MDBox display="flex" mb={2}>
          <MDTypography variant="h3">BlackList</MDTypography>
        </MDBox>
        <MDBox>
          {/* <div>
             <TwinButton/>
          </div> */}
       </MDBox>
     </MDBox>
      <MDBox>
        <MDBox my={4} style={{ overflowX: "auto" }}>
          <CustomTable />
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlackList;
