import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MailIcon from "@mui/icons-material/Mail";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import { Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import { ButtonGroup } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ScheduleCampaign from "layouts/dashboards/campaigns/schedulecampaign";

function NewsLetterPreview() {
  const [tabOrientation, setTabOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    function handleTabsOrientation() {
      const newOrientation = window.innerWidth < breakpoints.values.sm ? "vertical" : "horizontal";
      setTabOrientation(newOrientation);
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, []);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <MDBox p={3} gap={10}>
      <MDBox>
        <MDTypography variant="body1" mb={2}>
          Newsletter preview
        </MDTypography>
        <Card sx={{ bgcolor: "light.main", boxShadow: "none" }}>
          <Grid container display="flex" flexDirection="column" gap={1} p={2}>
            <Grid item display="flex" alignItems="center" gap={2}>
              <MDTypography variant="caption" color="secondary" fontWeight="regular">
                From
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {" "}
                Brooklyn Alice /Brooklynal@gmail.com/
              </MDTypography>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={2}>
              <MDTypography variant="caption" color="secondary" fontWeight="regular">
                Subject
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                Untitled
              </MDTypography>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={2}>
              <MDTypography variant="caption" color="secondary" fontWeight="regular">
                Opens Tracking
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                Enabled
              </MDTypography>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={2}>
              <MDTypography variant="caption" color="secondary" fontWeight="regular">
                Clicks Tracking
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                Enabled
              </MDTypography>
            </Grid>
            <Grid item display="flex" alignItems="center" gap={2}>
              <MDTypography variant="caption" color="secondary" fontWeight="regular">
                Web Version
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                https://alternsend.innonde.net/w/wfN039VY736A46QEJQMM3KA
              </MDTypography>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDBox my={3} display="flex" justifyContent="space-between" alignItems="center">
        <Tabs
          orientation={tabOrientation}
          value={tabValue}
          onChange={handleSetTabValue}
          style={{ width: "30%" }}
        >
          <Tab label="Desktop" />
          <Tab label="Mobile" />
        </Tabs>
        <ButtonGroup sx={{ bgcolor: "light.main", display: "flex", alignItems: "center", borderRadius: "30px" }}>
          <MDButton
            sx={{
              backgroundColor: "light.main",
              "&:hover": {
                backgroundColor: "light.main",
              },
            }}
          >
            <ArrowBackIcon />
          </MDButton>
          <MDTypography
            variant="button"
            fontWeight="regular"
            fontSize="medium"
            display="flex"
            align="center"
            sx={{ minWidth: 100 }}
          >
            Select a list
          </MDTypography>
          <MDButton
            sx={{
              backgroundColor: "light.main",
              "&:hover": {
                backgroundColor: "light.main",
              },
            }}
          >
            <ArrowForwardIcon />
          </MDButton>
        </ButtonGroup>
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <MDBox display="flex" flexDirection="column" flex={1} p={2} sx={{ maxWidth: "50%" }}>
          <MDTypography>Test send this Campaign</MDTypography>
          <MDTypography variant="button" fontWeight="regular" my={2}>
            Test email(s)
          </MDTypography>
          <TextField
            label="Email addresses, separated by commas"
            variant="standard"
            style={{ width: "100%" }}
          />
          <MDButton
            variant="contained"
            size="medium"
            color="light"
            sx={{
              width: "64%",
              padding: "2px 4px",
              marginTop: "14px",
              marginBottom: "10px",
              display: "flex",
              gap: "6px",
            }}
          >
            <MailIcon color="dark" fontSize="medium" />
            <MDTypography variant="body1" fontSize="14px">
              Test send this newsletter
            </MDTypography>
          </MDButton>
        </MDBox>
        <MDBox display="flex" flexDirection="column" flex={1} p={2} sx={{ maxWidth: "48%" }}>
          <MDTypography>Define Recipients</MDTypography>
          <MDTypography variant="button" fontWeight="regular" my={2}>
            Choose your lists & segments
          </MDTypography>
          <TextField label="lists & segments" variant="standard" style={{ width: "100%" }} />
          <MDButton
            variant="contained"
            size="medium"
            color="light"
            sx={{
              width: "78%",
              padding: "2px 4px",
              marginTop: "14px",
              marginBottom: "10px",
              display: "flex",
              gap: "6px",
            }}
          >
            <DoNotDisturbOnIcon color="dark" fontSize="medium" />
            <MDTypography variant="body1" fontSize="13px">
              Exclude lists from this Campaign?
            </MDTypography>
          </MDButton>
        </MDBox>
      </MDBox>

      <MDBox display="flex" justifyContent="space-between" gap={2} marginTop={4}>
        <MDButton variant="text" color="dark">
          <ArrowBackIosNewIcon />
          <MDTypography ml={1} variant="button" fontWeight="regular">
            Back
          </MDTypography>
        </MDButton>
        <MDBox display="flex" gap={2}>
          <MDButton variant="outlined" color="info" size="medium">
            Schedule this campaign?
          </MDButton>
          <MDButton variant="gradient" color="info" size="medium">
            Send Newsletter Now!
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default NewsLetterPreview;
