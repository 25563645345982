import { useState } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { faTrashCan, faDownload } from "@fortawesome/free-solid-svg-icons";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import TableButton from "layouts/applications/data-tables/pagination";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TwinButton from "components/TwinButton/TwinButton";
import InputFile from "assets/theme/components/inputTypeFile/InputFile";
import BlockedDomainsButton from "assets/theme/components/BlockedDomainsBtn/BlockedDomainsBtn";
import { textAlign } from "@mui/system";

const styles = {
  table: {
    width: "100%",
    borderCollapse: "collapse",
    border: "none",
    backgroundColor: "white",
    overflow: "auto",
  },
  th: {
    padding: "16px",
    textAlign: "center",
    color: "#364868",
    fontSize: "14px",
    fontWeight: "700",
    borderBottom: "1px solid #ddd",
  },
  thFirst: {
    paddingLeft: "16px",
    borderBottom: "1px solid #F0F2F5",
    textAlign: "left",
    fontSize: "14px",
    fontWeight: "700",
    color: "#364868",
  },
  td: {
    padding: "16px",
    textAlign: "center",
    fontSize: "12px",
    color: "#364868",
    borderBottom: "1px solid #F0F2F5",
  },
  tdFirst: {
    textAlign: "left",
    fontSize: "15px",
  },
  actionBtn: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
  },
  actionBtnIcon: {
    fontSize: "16px",
  },
  status: {
    backgroundColor: "#364868",
    color: "white",
    height: "40%",
    display: "inline-block",
    lineHeight: "20px",
    textAlign: "center",
    borderRadius: "5px",
    padding: "0px 5px 0px 5px",
  },
  attempts: {
    display: "inline-block",
    backgroundColor: "#4CAF50",
    color: "white",
    width: "30px",
    lineHeight: "30px",
    textAlign: "center",
    borderRadius: "30%",
  },
};

const initialData = [
  {
    id: 1,
    email: "onetwothree@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "4",
  },
  {
    id: 2,
    email: "testtest1@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "5",
  },
  {
    id: 3,
    email: "testtest1@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "5",
  },
  {
    id: 4,
    email: "testtest1@gmail.com",
    status: "suppressed",
    lastUpdate: "2 weeks ago",
    BlockAttempts: "5",
  },
];

const HeadersData = [
  { key: "Email", label: "Email" },
  { key: "Status", label: "Status" },
  { key: "LastUpdate", label: "Last Update" },
  { key: "BlockAttempts", label: "Block Attempts" },
  { key: "Delete", label: "Delete" },
];

const Domains = [{ email1: "mailchat.com", email2: "mailchat.com" }];


function blacklist() {
  const [activeStep, setActiveStep] = useState(0);
  

  const [data, setData] = useState(initialData);
  const [selectedTable, setSelectedTable] = useState(true);

  const handleDelete = (id) => {
    const newData = data.filter((item) => item.id !== id);
    setData(newData);
  };

  const steps = selectedTable ? ["Suppereson List"] : ["Blocked Domains"] ;
  
  const consolelogger = () => {
    setSelectedTable((prev) => !prev);
  };

  return (
    <div>
      <TwinButton onSelect={consolelogger} />

      <MDBox py={3} mb={20} height="65vh">
        <Grid style={{  marginTop:"20px"}}   container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
          <Grid item xs={12} lg={8}>
            <Formik initialValues={initialValues}>
              {() => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step style={{ fontSize: "30px", fontWeight: "bold" }} key={label}>
                            <StepLabel icon >{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox display="flex"></MDBox>
                      <div style={{ overflowX: "auto" }}>
                        {activeStep === 0 && selectedTable && (
                          <MDBox display="flex" style={{ paddingBottom: "20px" }}>
                            <MDButton
                              variant="contained"
                              size="medium"
                              sx={{
                                display: "flex",
                                fontSize: "14px",
                                padding: "10px",
                                fontWeight: "400",
                                textTransform: "unset",
                                boxShadow: "4px 4px 4px 0px #0000004D",
                                color: "#364868",
                                gap: "8px",
                                marginRight: "8px",
                              }}
                            >
                              <Icon>
                                <AddCircleIcon />
                              </Icon>
                              Create a new template
                            </MDButton>
                            <MDButton
                              variant="contained"
                              size="medium"
                              sx={{
                                display: "flex",
                                fontSize: "14px",
                                padding: "10px",
                                fontWeight: "400",
                                textTransform: "unset",
                                boxShadow: "4px 4px 4px 0px #0000004D",
                                color: "#364868",
                                gap: "8px",
                                marginRight: "8px",
                              }}
                            >
                              <Icon>
                                <FontAwesomeIcon icon={faDownload} style={{ color: "#364868" }} />
                              </Icon>
                              Export
                            </MDButton>
                            <MDButton
                              variant="contained"
                              size="medium"
                              sx={{
                                display: "flex",
                                fontSize: "14px",
                                padding: "10px",
                                fontWeight: "400",
                                textTransform: "unset",
                                boxShadow: "4px 4px 4px 0px #0000004D",
                                color: "#364868",
                                gap: "8px",
                                marginRight: "8px",
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashCan} style={{ color: "#364868" }} />
                              Delete All
                            </MDButton>
                          </MDBox>
                        )}

                        {activeStep === 0 && selectedTable ? (
                          <table style={{ ...styles.table }}>
                            <thead>
                              <tr>
                                {HeadersData.map((item) => (
                                  <th key={item.key} style={styles.th}>
                                    {item.label}
                                  </th>
                                ))}
                              </tr>
                            </thead>

                            <tbody>
                              {data.map((item) => (
                                <tr key={item.id}>
                                  <td style={styles.tdFirst}>{item.email}</td>
                                  <td style={styles.td}>
                                    <div style={styles.status}>{item.status}</div>
                                  </td>
                                  <td style={styles.td}>{item.lastUpdate}</td>
                                  <td style={styles.td}>
                                    <div style={styles.attempts}>{item.BlockAttempts}</div>
                                  </td>
                                  <td onClick={() => handleDelete(item.id)} style={styles.td}>
                                    <FontAwesomeIcon
                                      icon={faTrashCan}
                                      style={{ color: "#364868", cursor: "pointer" }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <div style={{ display: "flex" }}>
                            <div style={{ flex: "1 1 0" }}>
                              <h4>Import via CSV file</h4>

                              <MDBox>
                                <p>CSV format:</p>
                                <ul
                                  style={{
                                    listStyleType: "disc",
                                    marginLeft: "20px",
                                    fontSize: "15px",
                                  }}
                                >
                                  <li>
                                    Your CSV should only contain one column containing only domains
                                  </li>
                                  <li>
                                    Your CSV columns should be separated by commas, not semi-colons
                                    or any other characters
                                  </li>
                                </ul>
                              </MDBox>
                              <MDBox>
                                <div style={{ marginBottom: "15px" }}>
                                  {Domains.map((domain, index) => (
                                    <div
                                      style={{
                                        border: "1px solid #C7CCD0",
                                        borderRadius: "15px",
                                        padding: "15px",
                                        width: "308px",
                                        
                                      }}
                                      key={index}
                                    >
                                      <h3 style={{ fontSize: "20px" }}>domain</h3>
                                      <p style={{ fontSize: "15px" }}> {domain.email1}</p>
                                      <p style={{ fontSize: "15px" }}> {domain.email2}</p>
                                    </div>
                                  ))}
                                </div>
                                <InputFile />
                                <BlockedDomainsButton />
                              </MDBox>
                            </div>
                            <div
                              style={{
                                flex: "1 1 0",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                width:"100px"
                              }}
                            >
                              <div style={{
                                display : "flex",
                                flexDirection : "column"
                              }}>
                                <h4>Import domains per line</h4>
                                <p>Domains</p>
                                <textArea type={"text"} placeholder="mailchat.com" 
                                  style={{
                                    height: "255px",
                                    border: "1px solid #C7CCD0",
                                    borderRadius: "5px",
                                    paddingLeft: "10px",
                                    verticalAlign:'top',
                                    textAlign: 'left',
                                    resize: "none",
                                  }}
                                />
                              </div>
                              <BlockedDomainsButton/>
    
                             
                            </div>
                          </div>
                        )}
                      </div>
                      <MDBox mt={2} width="100%" display="flex">
                        <TableButton text={1} />
                        <TableButton icon={faChevronRight} />
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
}

export default blacklist;
